<template>
  <div
    class="base-slider-pc"
    :class="{
      'small-screen': small_screen,
    }"
    :style="{
      right: getSliderRight,
    }"
  >
    <div class="item new_container" @click="$emit('newManChest')">
      <div class="new"></div>
      <span>新人宝箱</span>
    </div>

    <div class="item" @click="gotTo('/task-center')">
      <img
        src="@/assets/img/index/59b1698f-2699-4466-b8a2-1b7c58150087.webp"
        style="width: 30px; height: auto"
      />
      <span>签到</span>
    </div>
    <!--<div class="item lucky-money_container" @click="$emit('hongbao')">
      <div class="lucky-money"></div>
      <span>红包</span>
    </div>
    <div class="item chat-group_container" @click="qqGroup()">
      <div class="chat-group"></div>
      <span>群聊</span>
    </div>-->
    <div class="item helper_container" @click="gotTo('/Payment')">
      <div class="helper"></div>
      <span>充值</span>
    </div>
    <div class="item back_container" @click="$emit('retrieveTheAssistant')">
      <div class="back"></div>
      <span>取回助手</span>
    </div>

    <div class="item back-top_container" @click="backTop">
        <img
        class="back-top"
        src="@/assets/img/index/de1069ab-1fd6-46e3-b449-7b580c3d5f0a.webp"
      />
      <span>顶置</span>
    </div>
    <div class="switch" @click="changeSlider">
      <i :class="[status ? 'el-icon-arrow-left' : 'el-icon-arrow-right']"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 小屏
      small_screen: false,
      // 状态 0展开 1折叠
      status: 0,
    };
  },
  computed: {
    getSliderRight() {
      if (this.small_screen) {
        if (this.status) {
          return "-65px !important";
        } else {
          return "-10px !important";
        }
      } else {
        if (this.status) {
          return "-418px !important";
        } else {
          return "0px !important";
        }
      }
    },
  },
  created() {
    this.checkScreen();
    window.addEventListener("resize", () => {
      this.checkScreen();
    });
  },
  methods: {
    changeSlider() {
      this.status = this.status ? 0 : 1;
    },
    checkScreen() {
      if (window.innerWidth < 600) {
        this.small_screen = true;
        return;
      }
      this.small_screen = false;
    },
    backTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
    gotTo(path) {
      this.$router.push({
        path,
      });
    },
    qqGroup() {
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=s7qihLq_4vvaIaOwNlsGQDq6e3wSxdzM&jump_from=webapi"
      );
    },
    openHongbao() {
      this.$emit("openHongbao");
    },
  },
};
</script>

<style lang="less" scoped>
div {
  -webkit-tab-highlight-color: transparent;
}
.small-screen {
  transform: scale(0.8) translateY(50%) !important;
  right: -10px !important;
}
.base-slider-pc {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  right: 0;
  bottom: calc(10vh);
  width: 370px;
  z-index: 600;
  background-color: #070b11c7;
  // min-height: 200px;
  padding: 20px 0;
  transition: all 200ms ease-in-out;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transform: scale(1.1) translateY(50%);
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  padding-right: 30px;
  .switch {
    background-color: #070b11c7;
    height: 112px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(calc(-50% + 35px));
    text-align: center;
    i {
      color: #fff;
      font-size: 25px;
    }
  }

  .lucky-money_container {
    .lucky-money {
      width: 19.39px;
      height: 21.55px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/index/0985c3f1-e406-4fe1-8ec3-523325fdf746.webp");
    }
    &:hover {
      .lucky-money {
        background-image: url("../../../assets/img/index/d857d42b-9347-4b16-a309-359513068b6e.png");
      }
    }
  }
  .chat-group_container {
    .chat-group {
      width: 19.39px;
      height: 21.55px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/index/371e2603-1c1c-4d87-b183-64a519e61ee2.webp");
    }
    &:hover {
      .chat-group {
        background-image: url("../../../assets/img/index/8ba127e2-c21e-41a8-a684-e45e301f4dbe.png");
      }
    }
  }
  .back-top_container {
    .back-top {
      width: 22px;
      height: 22px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/index/de1069ab-1fd6-46e3-b449-7b580c3d5f0a.webp");
    }
    &:hover {
      .back-top {
        background-image: url("../../../assets/img/index/3dfe3ebb-2753-471b-933b-a9486a730859.png") !important;
      }
    }
  }

  .helper_container {
    .helper {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/index/cz.png");
    }
    &:hover {
      .helper {
        background-image: url("../../../assets/img/index/cz_s.png");
      }
    }
  }

  .back_container {
    .back {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/index/zh.png");
    }
    &:hover {
      .back {
        background-image: url("../../../assets/img/index/zh_s.png");
      }
    }
  }

  .new_container {
    .new {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/index/2dfc3aa9-6317-4f50-9672-d6e956884dbb.png");
    }
    &:hover {
      .new {
        background-image: url("../../../assets/img/index/2dfc3aa9-6317-4f50-9672-d6e956884dbb.png");
      }
    }
  }

  .item {
    color: #fff;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
    span {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
